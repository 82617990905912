import React from 'react';
import { Box, Container, Heading, SimpleGrid, VStack, Text, Icon, useColorModeValue } from '@chakra-ui/react';
import { FaPlane, FaCogs, FaPaintBrush, FaTachometerAlt, FaSyncAlt, FaCloudUploadAlt, FaHeadset, FaUsers } from 'react-icons/fa';

const features = [
    { icon: FaPlane, title: 'Detailed Aircraft Models', description: 'Highly detailed and realistic aircraft models.' },
    { icon: FaCogs, title: 'Realistic Dynamics', description: 'Realistic flight dynamics and physics.' },
    { icon: FaPaintBrush, title: 'Customizable Liveries', description: 'Wide variety of customizable liveries.' },
    { icon: FaTachometerAlt, title: 'Advanced Cockpit Systems', description: 'State-of-the-art cockpit systems.' },
    { icon: FaSyncAlt, title: 'Regular Updates', description: 'Frequent updates and support.' },
    { icon: FaCloudUploadAlt, title: 'Easy Installation', description: 'Simple and quick installation process.' },
    { icon: FaHeadset, title: 'Customer Support', description: 'Dedicated customer support team.' },
    { icon: FaUsers, title: 'Community Forums', description: 'Active and engaging community forums.' }
];

const FeatureCard = ({ icon, title, description }) => {
    const bg = useColorModeValue("gray.100", "gray.700");
    const hoverBg = useColorModeValue("gray.200", "gray.600");

    return (
        <VStack
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            transition="background-color 0.2s"
            _hover={{ bg: hoverBg }}
        >
            <Icon as={icon} w={10} h={10} color="orange.400" />
            <Heading as="h3" size="md" mt={4}>{title}</Heading>
            <Text fontSize="md" textAlign="center">{description}</Text>
        </VStack>
    );
};

const Features = () => {
    return (
        <Box id="features" bg="white" py={16}>
            <Container maxW="container.lg">
                <Heading as="h2" size="xl" mb={8}>Features</Heading>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} />
                    ))}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default Features;
