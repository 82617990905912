import React, { useState } from 'react';
import { Box, Container, Heading, SimpleGrid, Image } from '@chakra-ui/react';

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageSrc) => {
        // Toggle selection: if clicked again, it closes
        setSelectedImage(selectedImage === imageSrc ? null : imageSrc);
    };

    return (
        <Box id="gallery" bg="gray.100" py={16} position="relative">
            <Container maxW="container.lg">
                <Heading as="h2" size="xl" mb={8}>Gallery</Heading>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                    {Array.from({ length: 8 }).map((_, index) => {
                        const imageSrc = `./images/image${index + 1}.png`;
                        return (
                            <Box
                                key={index}
                                position="relative"
                                overflow="hidden"
                                borderRadius="lg"
                                boxShadow={selectedImage === imageSrc ? 'outline' : 'base'}
                                transform={selectedImage === imageSrc ? 'scale(1.1)' : 'none'}
                                zIndex={selectedImage === imageSrc ? 1 : 0}
                                transition="all 0.3s ease"
                                onClick={() => handleImageClick(imageSrc)}
                            >
                                <Image
                                    src={imageSrc}
                                    alt={`Flight Simulator Image ${index + 1}`}
                                    objectFit="cover"
                                    width="100%"
                                    height="100%"
                                    transition="transform 0.3s ease"
                                    transform={selectedImage === imageSrc ? 'scale(1.1)' : 'none'}
                                />
                            </Box>
                        );
                    })}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default Gallery;
