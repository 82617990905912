import React from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';

const About = () => {
    return (
        <Box id="about" bg="gray.100" py={16}>
            <Container maxW="container.lg">
                <Heading as="h2" size="xl" mb={4}>About Us</Heading>
                <Text fontSize="lg" mb={4}>Horizon Simulations specializes in creating top-notch flight simulation experiences for Microsoft Flight Simulator. Our team is dedicated to providing the most immersive and realistic aircraft add-ons available.</Text>
                <Text fontSize="lg" mb={4}>Founded in 2022, we have quickly become a trusted name in the flight simulation community. Our products are designed by experienced developers and aviation enthusiasts who share a passion for creating lifelike simulation experiences.</Text>
                <Text fontSize="lg">We constantly update our products to keep up with the latest advancements in simulation technology, ensuring that our users always have the best experience possible.</Text>
            </Container>
        </Box>
    );
};

export default About;