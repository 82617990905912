import React from 'react';
import { Box, Flex, HStack, Link, IconButton, Image, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box bg="gray.800" px={4} position="fixed" width="100%" zIndex="1000">
            <Flex h={16} alignItems="center" justifyContent="space-between">
                <Flex align="center">
                    <Image src="./images/hs_logo.png" alt="Horizon Simulations Logo" boxSize="40px" mr={2} />
                    <Box color="white" fontSize="xl" fontWeight="bold">Horizon Simulations</Box>
                </Flex>
                <IconButton
                    size="md"
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label="Open Menu"
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={8} alignItems="center" display={{ base: 'none', md: 'flex' }}>
                    <Link href="#about" color="white">About</Link>
                    <Link href="#features" color="white">Features</Link>
                    <Link href="#gallery" color="white">Gallery</Link>
                    <Link href="#contact" color="white">Contact</Link>
                </HStack>
            </Flex>
        </Box>
    );
};

export default Header;
