import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Footer = () => {
    return (
        <Box as="footer" py={4} bg="gray.800" color="white" textAlign="center">
            <Text>&copy; 2024 Horizon Simulations. All Rights Reserved.</Text>
        </Box>
    );
};

export default Footer;