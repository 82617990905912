import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';

const Hero = () => {
    return (
        <Box position="relative" height="100vh" display="flex" alignItems="center" justifyContent="center" textAlign="center" color="white">
            <video autoPlay loop muted style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}>
                <source src='./horizon.mp4' type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Box position="absolute" top="140px" width="100%" zIndex={1} textAlign="center">
                <Heading as="h1" size="2xl" mb={4}>Experience the Sky Like Never</Heading>
                <Text fontSize="xl" mb={8}>Bringing the most realistic flight simulations to your fingertips.</Text>
                <Button colorScheme="orange" size="lg" as="a" href="#features">Learn More</Button>
            </Box>
        </Box>
    );
};

export default Hero;