import React from 'react';
import { Box, Container, Heading, FormControl, FormLabel, Input, Textarea, Button } from '@chakra-ui/react';

const Contact = () => {
    return (
        <Box id="contact" bg="white" py={16}>
            <Container maxW="container.md">
                <Heading as="h2" size="xl" mb={8}>Contact Us</Heading>
                <form>
                    <FormControl id="name" mb={4} isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" />
                    </FormControl>
                    <FormControl id="email" mb={4} isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" />
                    </FormControl>
                    <FormControl id="message" mb={4} isRequired>
                        <FormLabel>Message</FormLabel>
                        <Textarea rows={3} />
                    </FormControl>
                    <Button type="submit" colorScheme="orange">Send Message</Button>
                </form>
            </Container>
        </Box>
    );
};

export default Contact;